<template>
  <div class="flex">
    <div class="img_list" v-for="(item, idx) in fileList" :key="idx">
      <img
        class="image"
        width="100%"
        v-if="item.url.indexOf('.pdf') > -1"
        :src="require('@/assets/images/desgin/icon_pdf.png')"
        alt=""
      />
      <img
        class="image"
        width="100%"
        v-if="item.url.indexOf('.dwg') > -1"
        :src="require('@/assets/images/desgin/icon_dwg.png')"
        alt=""
      />
      <img class="image" v-if="item.url.indexOf('.dwg') < 0 && item.url.indexOf('.pdf') < 0" :src="item.url" alt="加载中..." />
      <div class="masking">
        <i
          @click="handleDownload(item.url)"
          class="el-icon-download"
          style="margin-right: 5px"
        ></i>
        <i @click="del(idx)" class="el-icon-delete"></i>
      </div>
    </div>
    <div style="position: relative">
      <div v-if="fileList.length >= limitLen" class="uploadMask"></div>
      <el-upload
        class="upload-demo"
        :show-file-list="false"
        :multiple="true"
        :limit="limitLen"
        action="xx"
        list-type="text"
        :accept="uploadAccept"
        :http-request="uploadImg"
        :before-upload="beforeUpload"
        :on-remove="removeImg"
        :file-list="fileList"
      >
        <i
          v-if="fileList.length < limitLen"
          style="font-size: 14px; color: #016fff"
          class="el-icon-plus"
        ></i>
        <span
          v-if="fileList.length < limitLen"
          style="font-size: 14px; color: #016fff;"
          >点击上传</span
        >
        <span class="fileLength">（{{ fileList.length }}）</span>
      </el-upload>

      <div class="tip">{{ tip }}</div>
    </div>
  </div>
</template>
<script>
import { IMG_URL_PRE } from "@/config";
import { uploadFile } from "@/service/common";
export default {
  name: "UploadComp",
  model: {
    prop: "imgs",
    event: "change",
  },
  props: {
    imgs: {
      type: Array,
      default: () => {},
    },
    prefix: {
      type: String,
      default: "",
      require: true,
    },
    uploadAccept: {
      type: String,
      default: "*",
    },
    limitLen: {
      type: Number,
      default: 1,
    },
    tip: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      fileList: [],
    };
  },
  created() {
    if (this.imgs && this.imgs.length > 0) {
      for (let img of this.imgs) {
        if (img.indexOf(this.imgUrl) == -1) {
          this.fileList.push({ url: this.imgUrl + img });
        } else {
          this.fileList.push({ url: img });
        }
      }
    }
    // console.log(this.imgs);
  },
  methods: {
    handleDownload(file) {
      console.log('预览', file)
      window.open(file)
    },
    async del(index) {
      try {
        await this.$confirm("确定删除该图片？", "温馨提示");
        this.fileList.splice(index, 1);
      } catch (e) {
        this.$message.info("取消");
      }
    },
    onChange() {
      let _imgs = this.fileList.reduce((pre, cur) => {
        let url = cur.url.replace(this.imgUrl, "");
        pre.push(url);
        return pre;
      }, []);
      this.$emit("change", _imgs);
    },
    beforeUpload(file) {
      if (this.uploadAccept === '*') {
        return true;
      }
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      let flag = false;
      this.uploadAccept.split(",").forEach((ext) => {
        if (ext.indexOf(testmsg) > -1) {
          flag = true;
        }
      });
      if (!flag) {
        this.$message.error("上传文件格式不正确！");
      }
      return flag;
    },
    uploadImg(item) {
      // console.log(item);
      console.log('文件类型',item.file.type)
      uploadFile(item.file, this.prefix, {
        onUploadProgress: (event) => {
          item.file.percent = (event.loaded / event.total) * 100;
          item.onProgress(item.file);
        },
        
        headers: {"Content-Type": `${item.file.type}`}
      })
        .then((url) => {
          this.fileList.push({
            status: "success",
            uid: new Date().getTime(),
            url: IMG_URL_PRE + url,
          });
          this.onChange();
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    //删除图片钩子
    beforeRemove(file, fileList) {
      let a = true;
      if (file && file.status === "success") {
        a = this.$confirm("确定删除该图片？", "温馨提示");
      }
      return a;
    },
    removeImg(file, fileList) {
      this.fileList = fileList;
      this.onChange();
    },
  },
};
</script>
<style lang="less" scoped>
.upload-demo {
  height: 40px;
  line-height: 40px;
  margin-left: 10px;
}

.img_list:hover .masking {
  display: block;
}
.img_list {
  margin: 0 5px;
  position: relative;
  cursor: pointer;

  .image {
    width: 62px;
    height: 62px;
  }
}
.fileLength {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(128, 128, 128, 1);
  // padding-top: 53px;
  margin-left: 5px;
}
.masking {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 71px;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  color: #fff;
  display: none;
}
.tip {
  font-size: 12px;
  color: #808080;
  margin-left: 10px;
}
.uploadMask {
  width: 80px;
  height: 50px;
  position: absolute;
  top: -10px;
  left: 0;
  /* background: red; */
  z-index: 1;
}
</style>