import { render, staticRenderFns } from "./UploadIdcard.vue?vue&type=template&id=599eb581&scoped=true&"
import script from "./UploadIdcard.vue?vue&type=script&lang=js&"
export * from "./UploadIdcard.vue?vue&type=script&lang=js&"
import style0 from "./UploadIdcard.vue?vue&type=style&index=0&id=599eb581&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599eb581",
  null
  
)

export default component.exports