const URLS = {
    "prod": {
        MES_STANDARD_URL: "http://175.178.78.123:30323/",
        COMMON_API: 'https://consul.mes.emergen.cn:17014/api',
        APS_API: 'http://redmine.deepsingularity.net:8004',
        SERVER_USER_API: 'http://175.178.78.123:30331/uc/v1',
        SERVER_ITEM_API: 'http://175.178.78.123:30331/pc/v1',
        CONTRACT_API: 'http://demo.emergen.cn:8021/ht-s2c/v1',
        BASEINFO_API: 'http://demo.emergen.cn:4011/api',
        SALE_API: 'http://demo.emergen.cn:4003/api',
        PROP_API: 'http://demo.emergen.cn:4013/api',
        GOV_API: 'http://demo.emergen.cn:4007/api',
        PAY_API: "http://demo.emergen.cn:4009/api",
        ORDER_API: "http://demo.emergen.cn:4017/api",
        FILE_API: "http://demo.emergen.cn:4015/api",
        IMG_URL_PRE: "http://cdn.file.nk.emergen.cn/dev/",
        MES_URL: "http://demo.emergen.cn:8100/",
        MES_PORTAL_URL: "http://demo.emergen.cn:8300",
        FINANCIAL_CENTER_URL: "https://scfhome.nk001.com/",
        MES_API: "http://175.178.78.123:30310",
    },
    "development": {
        MES_STANDARD_URL: "https://cloud-mes.xbee.emergen.cn/",
        // COMMON_API: 'https://consul.mes.emergen.cn:17014/api',
        // APS_API: 'http://redmine.deepsingularity.net:8004',
        SERVER_USER_API: ' https://ztserver-mes-dev.xbee.emergen.cn/uc/v1',
        SERVER_ITEM_API: ' https://ztserver-mes-dev.xbee.emergen.cn/pc/v1',
        // CONTRACT_API: 'http://demo.emergen.cn:8021/ht-s2c/v1',
        // BASEINFO_API: 'http://demo.emergen.cn:4011/api',
        // SALE_API: 'http://demo.emergen.cn:4003/api',
        // PROP_API: 'http://demo.emergen.cn:4013/api',
        // GOV_API: 'http://demo.emergen.cn:4007/api',
        // PAY_API: "http://demo.emergen.cn:4009/api",
        // ORDER_API: "http://demo.emergen.cn:4017/api",
        // FILE_API: "http://demo.emergen.cn:4015/api",
        // IMG_URL_PRE: "http://cdn.file.nk.emergen.cn/dev/",
        // MES_URL: "http://demo.emergen.cn:8100/",
        // MES_PORTAL_URL: "http://demo.emergen.cn:8300",
        // FINANCIAL_CENTER_URL: "https://scfhome.nk001.com/",
        MES_API: "https://api-mes-dev.xbee.emergen.cn",
    }
}
/**
 * 用户管理api (postToUserServer)
 */
export const SERVER_USER_API = URLS[process.env.VUE_APP_TITLE].SERVER_USER_API;
/**
 * 商品交易api (postToItemServer)
 */
export const SERVER_ITEM_API = URLS[process.env.VUE_APP_TITLE].SERVER_ITEM_API;
/**
 * 合同中心api (postToContractServer)
 */
export const CONTRACT_API = URLS[process.env.VUE_APP_TITLE].CONTRACT_API;
/**
 * 文件服务api
 */
export const FILE_API = URLS[process.env.VUE_APP_TITLE].FILE_API;
/**
 * 图片服务
 */
export const IMG_URL_PRE = URLS[process.env.VUE_APP_TITLE].IMG_URL_PRE;
/**
 * 静态资源服务
 */
export const STATIC_URL_PRE = "https://cdn.file.nk.emergen.cn/";
/**
 * 销售公司api (postToSaleServer)
 */
export const SALE_API = URLS[process.env.VUE_APP_TITLE].SALE_API;
/**
 * 销售公司分销api (张朝云--postToZCYShareServer)
 */
export const ZCY_SHARE_API = URLS[process.env.VUE_APP_TITLE].ZCY_SHARE_API;
/**
 * 销售公司分销api (田果--postToZCYShareServer)
 */
export const TG_SHARE_API = URLS[process.env.VUE_APP_TITLE].TG_SHARE_API;
/**
 * 销售公司道具api (postToPropServer)
 */
export const PROP_API = URLS[process.env.VUE_APP_TITLE].PROP_API;
/**
 * 政府政策api (postToGovServer)
 */
export const GOV_API = URLS[process.env.VUE_APP_TITLE].GOV_API;
/**
 * 基础资料()
 */
export const BASEINFO_API = URLS[process.env.VUE_APP_TITLE].BASEINFO_API;
/**
 * 支付api (postToPayServer)
 */
export const PAY_API = URLS[process.env.VUE_APP_TITLE].PAY_API;
/**
 * 交易中心api (postToOrderServer)
 */
export const ORDER_API = URLS[process.env.VUE_APP_TITLE].ORDER_API;
/**
 * MES前端url
 */
export const MES_URL = URLS[process.env.VUE_APP_TITLE].MES_URL;

/**
 * MES标准版前端url
 */
export const MES_STANDARD_URL = URLS[process.env.VUE_APP_TITLE].MES_STANDARD_URL;

/**
 * MES前端url(云工厂、共享中心--)
 */
export const MES_PORTAL_URL = URLS[process.env.VUE_APP_TITLE].MES_PORTAL_URL;
/**
 * MES前端url(木材超市)
 */
export const MOOD_URL = URLS[process.env.VUE_APP_TITLE].MOOD_URL;
/**
 * MES前端url(供应商)
 */
export const SUPPLIER_URL = URLS[process.env.VUE_APP_TITLE].SUPPLIER_URL;
/**
 * 外包前端url(门户四大中心)
 */
export const PORTAL_URL = URLS[process.env.VUE_APP_TITLE].PORTAL_URL;
/**
 * 外包前端url(门户金融服务中心)
 */
export const FINANCIAL_CENTER_URL = URLS[process.env.VUE_APP_TITLE].FINANCIAL_CENTER_URL;
/**
 * Mes url
 */

export const MES_API = URLS[process.env.VUE_APP_TITLE].MES_API;
/**
 *  云版权
 */
export const COPYRIGHT_URL = URLS[process.env.VUE_APP_TITLE].COPYRIGHT_URL;
/**
 * Mes 云物流
 */
export const CLOUD_LOGISTIC_API = URLS[process.env.VUE_APP_TITLE].CLOUD_LOGISTIC_API;
/**
 * 云金融
 */
export const CLOUD_FINANCIAL_API = URLS[process.env.VUE_APP_TITLE].CLOUD_FINANCIAL_API;
/**
 * 云金融
 */
export const CLOUD_FINANCIAL_URL = URLS[process.env.VUE_APP_TITLE].CLOUD_FINANCIAL_URL;
/**
 * 当前域名
 */
export const BASE_URL = `${window.location.origin}`;
/**
 * APS 信息 url
 */
export const APS_API = URLS[process.env.VUE_APP_TITLE].APS_API;

/**
 * 物流
 */
export const BLC_API = URLS[process.env.VUE_APP_TITLE].BLC_API;

/**
 *  通用api新
 */
export const COMMON_API = URLS[process.env.VUE_APP_TITLE].COMMON_API;

/**
 *  讯集思
 */
export const XJS_URL = URLS[process.env.VUE_APP_TITLE].XJS_URL
